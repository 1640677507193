import React from "react";
import { Elements } from "prismic-reactjs";
import { OverlayTrigger, Popover } from "react-bootstrap";

const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key });
};

export const addGifToContentOld = gifs => {
  const gifTags = gifs.map(gif => gif.word.text);

  return (type, element, content, children, key) => {
    if (type === Elements.strong) {
      for (let i = 0; i < gifTags.length; i++) {
        if (content.includes(`<${gifTags[i]}>`)) {
          const { gif, word } = gifs.find(gif => gif.word.text === gifTags[i]);
          const props = {
            trigger: "focus",
            placement: "top",
            overlay: (
              <Popover className="custom-popover">
                <Popover.Content>
                  <img src={gif.url} alt={word.text || ""} className="w-100" />
                </Popover.Content>
              </Popover>
            ),
          };

          return React.createElement(
            OverlayTrigger,
            propsWithUniqueKey(props, key),
            React.createElement(
              "a",
              {
                tabIndex: 0,
                className: "gif-trigger",
              },
              content
                .replace(`<${gifTags[i]}>`, "")
                .replace(`</${gifTags[i]}>`, "")
            )
          );
        }
      }
    }
    return null;
  };
};

export const addGifToContent = () => {
  return (type, element, content, children, key) => {
    if (type === Elements.hyperlink && element.data.link_type === "Media") {
      const props = {
        trigger: ["hover", "focus"],
        placement: "top",
        delay: { show: 250, hide: 400 },
        overlay: (
          <Popover className="custom-popover">
            <Popover.Content>
              <img
                src={element.data.url}
                alt={element.data.name || ""}
                className="w-100"
              />
            </Popover.Content>
          </Popover>
        ),
      };

      return React.createElement(
        OverlayTrigger,
        propsWithUniqueKey(props, key),
        React.createElement(
          "a",
          {
            tabIndex: 0,
            className: "gif-trigger",
          },
          content
        )
      );
    }
    return null;
  };
};
